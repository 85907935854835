<template>
  <nav class="navbar is-fixed-top is-dark" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/">
        <img src="https://bulma.io/images/bulma-logo-white.png" alt="Bulma: Free, open source, and modern CSS framework based on Flexbox" width="112" height="28">
      </router-link>
      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbar-menu">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div id="navbar-menu" class="navbar-menu">
      <div class="navbar-start">
        <router-link class="navbar-item" to="/invoices" active-class="is-active"> Facturas </router-link>
        <router-link class="navbar-item" to="/comprobantes" active-class="is-active"> Comprobantes </router-link>
        <router-link class="navbar-item" to="/totais" active-class="is-active"> Soma por periodo </router-link>
      </div>
      <div class="navbar-end" v-if="isSignedIn()">
        <a class="navbar-item has-text-danger" @click="signOut"> Salir </a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  methods: {
    isSignedIn () {
      return !!this.$store.state.signedIn
    },
    signOut () {
      this.$http.secured.delete('/api/signin')
        .then(response => {
          delete localStorage.csrf
          delete localStorage.signedIn
          this.$router.push('/')
         })
         .catch(error => console.log(error, 'Cannot sign out'))
    }
  }
}
</script>
