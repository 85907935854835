<template>
  <div class="content has-text-centered">
    <h1> Page not found </h1>
  </div>
</template>

<script>
export default {
  name: 'not-found',
  components: {
  }
}
</script>
