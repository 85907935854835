<template>
<div class="content is-flex">
  <div class="column is-half">
    <form class="form-app form-signin" @submit.prevent="signin">
      <div class="field">
        <label class="label"> Email </label>
        <div class="control has-icons-left has-icons-right">
          <input v-model="email" class="input" type="email" placeholder="email@exemplo.com">
          <span class="icon is-small is-left">
            <i class="fas fa-envelope"></i>
          </span>
        </div>
      </div>
      <div class="field">
        <label class="label"> Contraseña </label>
        <div class="control has-icons-left has-icons-right">
          <input v-model="password" class="input" type="password" placeholder="Senha">
          <span class="icon is-small is-left">
            <i class="fas fa-lock"></i>
          </span>
        </div>
      </div>
      <!--
      <div class="field">
        <label class="label">Email</label>
        <div class="control has-icons-left has-icons-right">
          <input class="input is-danger" type="email" placeholder="email@exemplo.com" value="">
          <span class="icon is-small is-left">
            <i class="fas fa-envelope"></i>
          </span>
          <span class="icon is-small is-right">
            <i class="fas fa-exclamation-triangle"></i>
          </span>
        </div>
        <p class="help is-danger">This email is invalid</p>
      </div>
      -->
      <div class="field" v-if="error">
        <div class="notification is-danger"> {{ error }} </div>
      </div>
      <div class="field">
        <div class="control">
          <button class="button is-link is-success is-fullwidth">
            Login
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
</template>

<script>
export default {
  name: 'Signin',
  data () {
    return {
      email: '',
      password: '',
      error: ''
    }
  },
  created () {
    this.checkSignedIn()
  },
  updated () {
    this.checkSignedIn()
  },
  methods: {
    signin () {
      this.$http.plain.post('/api/signin', { email: this.email, password: this.password })
        .then(response => this.signinSuccessful(response))
        .catch(error => this.signinFailed(error))
    },
    signinSuccessful (response) {
      if (!response.data.csrf) {
        this.signinFailed(response)
        return
      }
      this.$http.plain.get('/api/me')
        .then(meResponse => {
          this.$store.commit('setCurrentUser', { currentUser: meResponse.data, csrf: response.data.csrf })
          this.error = ''
          this.$router.replace('/invoices')
        })
        .catch(error => this.signinFailed(error))
    },
    signinFailed (error) {
      this.error = (error.response && error.response.data && error.response.data.error) || ''
      this.$store.commit('unsetCurrentUser')
    },
    checkSignedIn () {
      if (this.$store.state.signedIn) {
        this.$router.replace('/invoices')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  align-items: center;
  justify-content: center;
}

</style>