import Vue from 'vue'
import VueRouter from 'vue-router'

import Signin from '../views/signin.vue'
import NotFoundComponent from  '../views/not_found.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Signin',
    // component: () => import(/* webpackChunkName: "Signin" */ '../views/signin.vue')
    component: Signin
  },
  {
    path: '/invoices',
    name: 'InvoiceList',
    component: () => import(/* webpackChunkName: "InvoiceList" */ '../views/invoice/list.vue')
  },
  {
    path: '/invoice/new',
    name: 'InvoiceNew',
    component: () => import(/* webpackChunkName: "InvoiceNew" */ '../views/invoice/new.vue')
  },
  {
    path: '/invoice/:id',
    name: 'InvoiceItem',
    component: () => import(/* webpackChunkName: "InvoiceItem" */ '../views/invoice/item.vue')
  },
  {
    path: '/invoice/:id/edit',
    name: 'InvoiceUpdate',
    component: () => import(/* webpackChunkName: "InvoiceUpdate" */ '../views/invoice/edit.vue')
  },

  {
    path: '/comprobantes',
    name: 'ComprobanteList',
    component: () => import(/* webpackChunkName: "ComprobanteList" */ '../views/comprobante/list.vue')
  },
  {
    path: '/comprobante/new',
    name: 'ComprobanteNew',
    component: () => import(/* webpackChunkName: "ComprobanteNew" */  '../views/comprobante/new.vue')
  },
  {
    path: '/comprobante/:id',
    name: 'ComprobanteItem',
    component: () => import(/* webpackChunkName: "ComprobanteItem" */  '../views/comprobante/item.vue')
  },
  {
    path: '/comprobante/:id/edit',
    name: 'ComprobanteUpdate',
    component: () => import(/* webpackChunkName: "ComprobanteUpdate" */  '../views/comprobante/edit.vue')
  },
  {
    path: '/totais',
    name: 'TotalList',
    component: () => import(/* webpackChunkName: "TotalList" */  '../views/total/list.vue')
  },
  
  { path: '*', component: NotFoundComponent }
  
  // // { path: '/', name: 'home', component: Home },
  // { path: '/', name: 'Signin', component: Signin },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
